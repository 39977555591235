@import "../../../stylesheets/settings";

.post-tile-wrap {
  padding-left: 10px !important;
  padding-right: 10px !important;
  .text__suggestions {
    border-color: #323232;
    z-index: 3 !important;
    .text__suggestions__item--focused {
      background: #fff;
      font-weight: bold;
      color: #323232 !important;
      .hashtag-suggestion {
        color: #323232;
        .tag-count {
          color: #757575; } } } }
  //------If you need to overwrite some styles for specific type of post, add additional class to component with ".post-tile" class
  //------to make your styles more important
  .post-tile {
    position: relative;
    border: 1px solid #F3F3F3;
    background-color: #fff;
    padding: 25px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
    .__react_component_tooltip.place-top:after {
      margin-left: -12px; }
    .post-tile-space-info {
      width: 70%;
      text-align: right;
      margin-top: 3px;
      font-size: 13px;
      color: #B7B7B7;
      padding: 0 1px;
      a {
        color: #58575F;
        text-decoration: none;
        padding-left: 12px; }
      .space-logo, .circle-image {
        display: inline-block;
        font-weight: bold;
        width: 18px;
        height: 18px;
        border: 1px solid white;
        border-radius: 100%;
        margin: 0;
        line-height: 180%;
        font-size: 9px;
        font-family: "Roboto", sans-serif;
        text-align: center;
        text-shadow: none;
        position: relative;
        bottom: 2px; }
      .circle-image {
        border-color: #F3F3F3; } }
    .post-tile-header {
      display: flex;
      margin-bottom: 18px;
      position: relative;
      align-items: center;
      .author-avatar {
        padding-right: 13px;
        img {
          width: 36px;
          height: 36px;
          border-radius: 100%; } }
      .author-info {
        white-space: pre-wrap;
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
        align-self: center;
        max-width: 65%;
        a {
          color: #323232;
          text-decoration: none; }
        .author-role-position {
          font-size: 11px;
          color: #BBBBBB;
          font-weight: normal;
          display: none;
          margin-top: 4px;
          @media (min-width: 991px) {
            display: block; } }
        & + div, + img.heart {
          margin-left: auto; } }
      .heart {
        width: 20px;
        height: 20px;
        margin-left: 20px;
        stroke: #D5D5D5;
        fill: #ffffff;
        overflow: visible;
        transition: stroke .3s, fill .3s;
        cursor: pointer;
        &.marked {
          fill: #F5351B;
          stroke: #F5351B; } }
      .pinned-post {
        height: 22px;
        width: 22px;
        overflow: visible;
        cursor: pointer; }
      .app-alerts-dropdown {
        display: initial;
        right: 0;
        top: 0;
        margin-left: 22px;
        position: absolute;
        user-select: none;
        cursor: pointer;
        z-index: 2;
        .options-wrap {
          position: absolute;
          display: none;
          width: 211px;
          border: 1px solid #E7E7E7;
          box-shadow: 1px 4px 8px rgba(120, 120, 120, 0.35);
          border-radius: 8px;
          top: 31px;
          left: -99px;
          background: white;
          padding: 6px 0;
          text-align: left;
          & > div {
            padding: 9px 18px 8px;
            font-size: 14px;
            color: #11A9FF;
            transition: color .3s;
            &:hover {
              color: #0065e2; }
            &:not(:last-child) {
              border-bottom: 1px solid #F3F3F3; } }
          &:before {
            width: 16px;
            height: 16px;
            position: absolute;
            content: '';
            left: 50%;
            top: 0;
            transform: translate(-3px, -50%) rotate(-45deg);
            border-top: 1px solid #E7E7E7;
            border-right: 1px solid #E7E7E7;
            background: white; }
          @media(max-width: $screen-md-max) {
            left: -170px;
            &:before {
              left: 81%; } } }
        &[custom-open="true"] .options-wrap {
          display: block; } }
      .post-dropdown {
        margin-left: 22px;
        position: relative;
        user-select: none;
        cursor: pointer;
        z-index: 2;
        .options-wrap {
          position: absolute;
          display: none;
          width: 182px;
          border: 1px solid #E7E7E7;
          box-shadow: 1px 4px 8px rgba(120, 120, 120, 0.35);
          border-radius: 8px;
          top: 31px;
          left: -84px;
          background: white;
          padding: 6px 0;
          & > div {
            padding: 9px 28px 8px;
            font-size: 14px;
            color: #11A9FF;
            transition: color .3s;
            &:hover {
              color: #0065e2; }
            &:not(:last-child) {
              border-bottom: 1px solid #F3F3F3; } }
          &:before {
            width: 16px;
            height: 16px;
            position: absolute;
            content: '';
            left: 50%;
            top: 0;
            transform: translate(-3px, -50%) rotate(-45deg);
            border-top: 1px solid #E7E7E7;
            border-right: 1px solid #E7E7E7;
            background: white; }
          @media(max-width: $screen-md-max) {
            left: -140px;
            &:before {
              left: 81%; } } }
        &[custom-open="true"] .options-wrap {
          display: block; } } }
    .poll-alert-body {
      .poll-alert-icon {
        display: block;
        margin: 0 auto 30px; }
      .vibe-anon {
        span {
          font-size: 11px;
          line-height: 13px;
          padding-left: 8px; } } }
    .app-alert-body {
      text-align: center;
      .app-alert-content {
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
        padding: 20px 0 30px; }
      .app-alert-btn {
        display: inline-block;
        background: #11A9FF;
        padding: 9px 18px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
        color: #ffffff; }
      .app-alert-feedback {
        display: inline-flex;
        width: 100%;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        padding-bottom: 20px;
        .feedback-paragraph {
          display: flex; }
        .feedback-counter {
          display: inline-block;
          background: #11A9FF;
          color: #ffffff;
          border-radius: 50%;
          min-width: 24px;
          width: 24px;
          height: 24px;
          text-align: center;
          font-weight: bold;
          padding-top: 2px;
          margin-right: 16px; } }
      .app-alert-header {
        font-size: 18px;
        line-height: 25px;
        font-weight: bold;
        padding-right: 30px;
        margin-bottom: 24px; }
      .show-feedback-btn {
        display: inline-block;
        background: #ffffff;
        border: 1px solid #11A9FF;
        padding: 9px 18px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
        color: #11A9FF;
        cursor: pointer;
        &.show-full-feedback {
          display: none; } }
      .feedback-wrapper {
        .feedback-info {
          font-size: 14px;
          line-height: 21px;
          font-weight: bold; }
        &.hide-full-feedback {
          overflow: hidden;
          max-height: 130px; } }
      svg.hide-full-feedback {
        position: absolute;
        bottom: 72px;
        left: 0; }
      svg.show-full-feedback {
        display: none; } }
    .post-tile-body {
      padding: 0 1px;
      a:hover {
        text-decoration: none; }
      a {
        cursor: pointer; }
      .post-details-wrap {
        display: flex;
        .post-details {
          width: 30%; } }
      .post-tile-media {
        margin: 21px -26px 22px -26px;
        position: relative;
        height: 342px;
        &.no-height {
          height: auto !important; }
        .slide {
          height: 342px;
          bottom: 0; }
        .img-responsive {
          margin: 0 auto; }
        .border {
          border-radius: 0 !important;
          border: 1px solid #fff !important;
          .cover {
            border-radius: 0 !important; } }
        div.img-div {
          height: 342px;
          background-size: cover;
          background-position: center 10%;
          background-repeat: no-repeat; }
        .carousel-inner {
          .item {
            height: 342px;
            .img-responsive {
              margin: auto;
              max-width: none;
              width: auto;
              height: 100%; } } }
        .carousel-indicators {
          position: absolute;
          z-index: 1;
          bottom: -8px !important; //important required to overwrite webview settings
          li {
            position: relative;
            width: 5px;
            height: 5px;
            background-color: rgba(255, 255, 255, 0.6);
            margin: 0 2px;
            top: -2px;
            transition: .1s height, .1s width, .1s top, .1s margin;
            &.active {
              background-color: #FFFFFF;
              width: 9px;
              height: 9px;
              top: 0;
              margin: 0; } } }
        .carousel-control {
          color: #DADADA;
          line-height: 30px;
          opacity: 1;
          width: 30px;
          &.right,
          &.left {
            background: none; }
          div {
            position: absolute;
            width: 13px;
            height: 13px;
            top: 50%;
            transform: translateY(-50%) rotateZ(-45deg) skew(-5deg, -5deg);
            border-bottom: 2px solid white;
            border-right: 2px solid white;
            &.control-right {
              right: 11px; }
            &.control-left {
              transform: translateY(-50%) rotateZ(135deg) skew(-5deg, -5deg);
              left: 11px; } } }
        .video-react {
          padding: 0 !important;
          min-height: 50px;
          height: 100%; } }
      .shoutout-receiver {
        text-align: center;
        .buzz-shoutout-title {
          font-weight: 500;
          line-height: normal;
          font-size: 18px;
          color: #323232;
          margin: 5px 0 20px;
          a {
            color: #323232; } } }
      .post-tile-title {
        margin-bottom: 20px;
        color: #323232;
        font-size: 18px;
        font-weight: bold;
        word-wrap: break-word;
        .type {
          color: #B7B7B7;
          line-height: 21px;
          &.black {
            color: #323232; } }
        h4.poll-content {
          line-height: 21px; } }
      .post-tile-content {
        color: #323232;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 14px;
        word-wrap: break-word;
        &.shoutout-buzz-post {
          text-align: center; } }
      .post-tile-possible-solution {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 14px;
        color: #323232;
        p {
          line-height: 20px;
          margin-top: 11px;
          font-weight: normal; } }
      .categories-wrapper {
        overflow-x: hidden;
        text-overflow: ellipsis;
        margin-top: 22px;
        margin-bottom: 27px;
        font-size: 14px;
        line-height: 22px;
        span {
          color: #323232;
          font-weight: bold; } }
      .tags-wrapper {
        margin-bottom: 24px;
        margin-top: -5px;
        a, span {
          max-width: 100%;
          overflow-x: hidden;
          text-overflow: ellipsis;
          text-decoration: none;
          font-size: 14px;
          line-height: 21px;
          background: #11A9FF;
          color: white;
          border-radius: 20px;
          padding: 6px 13px;
          font-weight: bold;
          margin-right: 7px;
          white-space: nowrap;
          display: inline-block;
          margin-top: 5px; } }
      .file-link {
        text-decoration: none;
        .file-tile {
          background: #F8F8F8;
          //background: linear-gradient(to left, #8BDBFF 0%, rgba(139, 219, 255, 0.5)  100%)
          display: flex;
          height: 40px;
          padding: 0 13px;
          border-radius: 6px;
          margin-bottom: 10px;
          position: relative;
          cursor: pointer;
          text-decoration: none;
          svg {
            align-self: center;
            margin-right: 12px; }
          .file-name {
            align-self: center;
            color: #11A9FF; } } }
      .time-ago, .views-counter {
        font-style: normal !important;
        font-weight: normal !important;
        line-height: normal !important;
        font-size: 12px !important;
        color: #D5D5D5 !important;
        margin-bottom: 20px;
        display: inline-block; }
      .time-ago {
        padding-right: 20px; }
      .vote-btn {
        width: 100%;
        @media(max-width: 330px) {
          width: 90%; }
        text-align: center;
        margin-top: 5px;
        margin-bottom: 20px;
        a {
          font-weight: 500;
          cursor: pointer;
          position: relative;
          font-size: 13px;
          line-height: 15px; } }
      div.poll-info {
        position: relative;
        height: 45px;
        .time-ago {
          position: absolute; } }
      .post-owners {
        height: 30px;
        cursor: pointer;
        margin-bottom: 25px;
        display: flex;
        justify-content: flex-start;
        a {
          text-decoration: none;
          &:hover {
            color: #323232; } }
        .media-left {
          padding-right: 45px; }
        a.post-owner-link {
          font-family: Roboto, sans-serif;
          font-style: normal;
          font-weight: normal;
          line-height: 13px;
          font-size: 13px;
          color: #323232;
          text-decoration: none; }
        .media-left.media-middle {
          position: relative;
          z-index: 0;
          img {
            width: 34px;
            height: 34px;
            border: 2px solid #fff;
            position: absolute;
            top: 0;
            left: 0;
            &.post-owner-multiple-icon {
              &:nth-child(2) {
                left: 18px;
                opacity: 0.95;
                z-index: -1; }
              &:nth-child(3) {
                left: 36px;
                opacity: 0.9;
                z-index: -2; }
              &:nth-child(4) {
                left: 54px;
                opacity: 0.85;
                z-index: -3; }
              &:nth-child(5) {
                left: 72px;
                opacity: 0.8;
                z-index: -4; }
              &:nth-child(6) {
                left: 90px;
                opacity: 0.75;
                z-index: -5; }
              &:nth-child(7) {
                left: 108px;
                opacity: 0.7;
                z-index: -6; } } } }
        p {
          font-family: Roboto, sans-serif;
          font-weight: normal;
          line-height: 34px;
          font-size: 13px;
          color: #323232;
          text-decoration: none;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 319px;
          height: 27px; } } }
    .comment-error {
      font-size: 12px;
      margin-top: 7px;
      color: #FE483C;
      margin-left: 61px; }
    .post-tile-comment-input {
      position: relative;
      display: flex;
      .media-control-panel {
        margin-left: 5px;
        img {
          cursor: pointer; } }
      .post-tile-icon-wrapper {
        font-family: Roboto, sans-serif;
        font-size: 13px;
        display: inline-flex;
        color: #323232;
        cursor: pointer;
        line-height: 40px;
        font-weight: bold;
        margin-left: -2px;
        margin-right: 13px;
        .post-tile-icon {
          width: 30px;
          height: 30px;
          background-color: #E6E6E6;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          color: #fff;
          display: inline-flex;
          margin-top: 5px;
          margin-right: 10px;
          font-size: 15px;
          justify-content: center;
          img {
            align-self: center;
            padding-bottom: 0; } }
        &.post-tile-liked {
          font-weight: bold;
          color: #11A9FF;
          .post-tile-icon {
            background-color: #11A9FF; } } }
      .mentions-input-wrap {
        border-radius: 4px;
        border: 1px solid #E7E7E7;
        flex-grow: 1;
        width: 80%;
        min-height: 40px;
        padding: 10px 34px 10px 14px;
        font-size: 14px;
        line-height: 16px;
        background: #FFFFFF;
        textarea {
          word-wrap: break-word;
          border: none;
          min-height: unset;
          &::placeholder {
            color: #B2B1C0;
            letter-spacing: .6px;
            font-size: 12px;
            line-height: 21px; } }
        &.error {
          border: 1px solid #F5351B;
          background: #FFF7F5; } } }
    .comment-dropzone-wrap {
      position: absolute;
      top: 7px;
      right: 16px;
      cursor: pointer;
      >div {
        height: auto !important;
        border: none !important;
        border-radius: 0 !important; }
      h3 {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        white-space: initial;
        color: #69BADE;
        z-index: 1;
        margin-bottom: 4px;
        line-height: 20px; } }
    .comment-images-wrap {
      text-align: left;
      line-height: 0;
      margin: 20px 0 20px 60px;
      .preview {
        border-radius: 3px !important;
        display: inline-block;
        width: 80px;
        background-size: cover;
        background-position: center;
        margin: 5px 15px 5px 5px;
        position: relative;
        &:before {
          content: 'x';
          position: absolute;
          top: -10px;
          right: -10px;
          width: 20px;
          height: 20px;
          background: #11A9FF;
          border-radius: 50%;
          cursor: pointer;
          color: #fff;
          line-height: 20px;
          text-align: center;
          font-weight: bold;
          font-size: 10px;
          z-index: 1; }
        .video-react-big-play-button {
          font-size: 1.5em; }
        .preview-img {
          max-width: 100%;
          height: auto;
          vertical-align: top; } }
      > div {
        height: 100%;
        .video-react-control-bar {
          display: none; }
        .video-react-big-play-button {
          width: 2em; } } }
    .post-tile-comments-section {
      margin: 25px -28px -28px;
      border-top: 1px solid #F3F3F3;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      ul {
        margin: 0;
        padding: 0;
        .comment {
          display: table;
          &.user-comment {
            font-size: 13px;
            padding: 0 26px 16px;
            .comment-content {
              display: table-row;
              vertical-align: top;
              padding-left: 0;
              @media (min-width: 1300px) {
                display: table-cell; }
              a {
                color: #323232;
                font-weight: 500; }
              span {
                width: 100%;
                text-align: justify;
                line-height: 18px;
                word-wrap: break-word;
                word-break: break-word;
                a {
                  color: #1297E3;
                  text-decoration: none; } }
              .comment-show-media {
                margin: 5px 0 0 0;
                width: 160px;
                @media (min-width: 460px) {
                  width: 200px; }
                &.single-image {
                  height: auto;
                  width: auto;
                  max-width: 200px;
                  margin-right: 0; }
                .img-responsive {
                  margin: auto; }
                .slide {
                  height: auto;
                  bottom: 0; }
                .border {
                  border-radius: 0 !important;
                  border: 1px solid #fff !important;
                  .cover {
                    border-radius: 0 !important; } }
                img {
                  width: 160px;
                  background-size: contain;
                  background-position: left top;
                  background-repeat: no-repeat;
                  @media (min-width: 460px) {
                    width: 200px; } }
                div.img-div {
                  height: 160px;
                  width: 160px;
                  background-size: contain;
                  background-position: left top;
                  background-repeat: no-repeat;
                  @media (min-width: 460px) {
                    width: 200px; } }
                .carousel-indicators {
                  position: absolute;
                  bottom: 10px;
                  li {
                    background-color: rgb(255,255,255);
                    background-color: rgba(255,255,255, 0.6);
                    &.active {
                      background-color: rgb(255,255,255);
                      background-color: rgba(255,255,255, 1); } } }
                .carousel-indicators {
                  bottom: -25px; }
                .carousel-inner {
                  .item {
                    height: 160px;
                    width: 160px;
                    div.img-div {
                      background-position: center; } } }
                .carousel-control {
                  color: #DADADA;
                  line-height: 30px;
                  opacity: 1;
                  width: 30px;
                  div {
                    position: absolute;
                    width: 13px;
                    height: 13px;
                    top: 50%;
                    transform: translateY(-50%) rotateZ(-45deg) skew(-5deg, -5deg);
                    border-bottom: 2px solid white;
                    border-right: 2px solid white;
                    &.control-right {
                      right: 11px; }
                    &.control-left {
                      transform: translateY(-50%) rotateZ(135deg) skew(-5deg, -5deg);
                      left: 11px; } } }
                .video-react {
                  padding: 0 !important;
                  min-height: 50px;
                  height: 100%; } } } }
          &:first-of-type {
            padding-top: 25px; }
          &:last-of-type {
            padding-bottom: 30px; } } }
      .react-tooltip.limit-reached {
        text-align: center;
        pointer-events: auto !important;
        background-color: #8B8A96;
        box-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px;
        z-index: 10;
        &::after {
          border-top-color: transparent !important;
          border-left-color: #8B8A96 !important;
          border-right-color: #8B8A96 !important; }
        span {
          a {
            color: white;
            text-decoration: underline; } } }

      .post-tile-comments-unfold {
        font-size: 12px;
        line-height: 16px;
        color: #11A9FF;
        cursor: pointer;
        padding: 0 26px 30px; }
      .post-tile-comments {
        padding: 0 2px;
        .post-tile-comment {
          margin-bottom: 16px;
          font-size: 14px;
          line-height: 18px;
          color: #323232;
          a {
            color: #323232;
            text-decoration: none;
            word-wrap: break-word;
            cursor: pointer; }
          span {
            margin-left: 7px;
            word-wrap: break-word;
            a {
              color: #11A9FF;
              white-space: nowrap; } }
          .inline-block {
            display: inline-block;
            vertical-align: text-top; } } } }
    .react-tooltip {
      background-color: #8B8A96;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
      border-radius: 0; }
    &.resolved {
      @media (min-width: 991px) {
        background: #ffffff;
        filter: none; }
      .post-tile-title {
        color: #4DA60B;
        img {
          margin-top: 3px; }
        .type {
          color: #4DA60B; } }
      .post-tile-marked-done {
        color: #4DA60B;
        font-weight: bold;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        margin: -3px 0 24px 1px;
        display: flex;
        img {
          width: 30px;
          height: 30px;
          margin-right: 13px;
          border-radius: 100%; }
        span {
          padding-top: 6px;
          a {
            color: #4DA60B; } } }
      .post-tile-icon-done {
        vertical-align: top;
        margin-right: 12px; } }
    .fix-tile-error {
      margin-top: 10px; }
    &.poll-tile {
      .poll-options {
        font-size: 14px;
        line-height: 16px;
        .pool-option-box {
          display: flex;
          margin-bottom: 12px;
          .option-content {
            position: relative;
            min-width: 50%;
            max-width: 90%;
            color: #323232;
            font-size: 14px;
            line-height: 19px;
            .vote-value {
              color: #11A9FF;
              position: absolute;
              right: -58px;
              top: 10px;
              font-weight: normal; }
            &.option-chosen {
              font-weight: bold; } }
          .round {
            margin-right: 12px; }
          .round label {
            border: 2px solid #D5D5D5;
            border-radius: 50%;
            cursor: pointer;
            height: 18px;
            width: 18px; }
          .round label:after {
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
            content: "";
            height: 5px;
            left: 2px;
            opacity: 0;
            position: absolute;
            top: 4px;
            transform: rotate(-45deg);
            width: 10px; }
          .round input[type="checkbox"] {
            visibility: hidden;
            width: 0; }
          .round input[type="checkbox"]:checked + label {
            cursor: default;
            background-color: $new-blue;
            border-color: $new-blue; }

          .round input[type="checkbox"]:checked + label:after {
            opacity: 1; } }
        .pool-option-box.selected {
          &:nth-of-type(1) {
            .option-content {
              background-color: #BDEBFF; } }
          &:nth-of-type(2) {
            .option-content {
              background-color: #DAF4FF; } }
          &:nth-of-type(3) {
            .option-content {
              background-color: #EFFAFF; } }
          &:nth-of-type(4) {
            .option-content {
              background-color: #EFFAFF; } }
          &.empty {
            .option-content {
              background-color: white; } }
          .round {
            padding-bottom: 15px; } } }
      .poll-options-expired {
        .pool-option-box {
          .option-content {
            color: #323232;
            font-size: 14px;
            line-height: 16px;
            .vote-value {
              color: #11A9FF; }
            .vote-progress-bar {
              background-color: #F8F8F8;
              height: 10px;
              margin-bottom: 13px;
              margin-top: 5px;
              .vote-color-bar {
                border-radius: 3px;
                height: 10px;
                background-color: #BDEBFF; } } } }
        .pool-option-box {
          &:nth-of-type(1) {
            .option-content {
              .vote-progress-bar {
                .vote-color-bar {
                  background-color: #11A9FF; } } } } } } }
    &.vibe-tile {
      .fullscreen-close {
        display: none !important; }
      .vibe-user-avatar {
        display: none !important; }
      .vibe-visible-only-info {
        font-size: 11px;
        color: #323232; }
      .vibe_new {
        .app-alerts-dropdown {
          display: initial;
          right: 0;
          top: 0;
          margin-left: 22px;
          position: absolute;
          user-select: none;
          cursor: pointer;
          z-index: 2;
          .options-wrap {
            position: absolute;
            display: none;
            width: 211px;
            border: 1px solid #E7E7E7;
            box-shadow: 1px 4px 8px rgba(120, 120, 120, 0.35);
            border-radius: 8px;
            top: 31px;
            left: -99px;
            background: white;
            padding: 6px 0;
            text-align: left;
            & > div {
              padding: 9px 18px 8px;
              font-size: 14px;
              color: #11A9FF;
              transition: color .3s;
              &:hover {
                color: #0065e2; }
              &:not(:last-child) {
                border-bottom: 1px solid #F3F3F3; } }
            &:before {
              width: 16px;
              height: 16px;
              position: absolute;
              content: '';
              left: 50%;
              top: 0;
              transform: translate(-3px, -50%) rotate(-45deg);
              border-top: 1px solid #E7E7E7;
              border-right: 1px solid #E7E7E7;
              background: white; }
            @media(max-width: $screen-md-max) {
              left: -170px;
              &:before {
                left: 81%; } } }
          &[custom-open="true"] .options-wrap {
            display: block; } }
        .shadowfilter {
          margin: 50px auto;
          animation: heelixGlow 2s infinite;
          @keyframes heelixGlow {
            0% {
              -webkit-filter: drop-shadow(0px 0px 15px rgba(255, 163, 19, 0.0)); }
            50% {
              -webkit-filter: drop-shadow(0px 0px 15px rgba(255, 163, 19, 0.5)); }
            100% {
              -webkit-filter: drop-shadow(0px 0px 15px rgba(255, 163, 19, 0.0)); } } }
        .panel {
          position: relative;
          margin-top: 0;
          margin-bottom: 0;
          box-shadow: none;
          @include mobile-only {
            margin-top: 0; }
          .panel-header {
            line-height: inherit;
            font-size: 18px;
            height: 100%;
            padding: 0 15px 15px;
            span {
              color: #323232;
              height: 100%;
              display: inline-block;
              vertical-align: middle; }
            .btn-previous {
              position: absolute;
              left: 0;
              top: -4px; } }
          .select_good_factors {
            .panel-footer {
              background-color: #11A9FF;
              .btn-next {
                color: #11A9FF;
                border-color: #11A9FF; } } }
          .default-factors-screen, .other-factors-screen, .why-factors-screen {
            .panel-body {
              padding: 0;
              @include mobile-only {
                height: calc(100% - 130px);
                overflow: auto;
                width: 100%; } }
            .panel-footer {
              margin-bottom: 25px; }
            .vibe-anon {
              bottom: -45px; } }
          .other-factors-screen {
            .panel-body {
              @include mobile-only {
                height: calc(100% - 50px); } }
            .panel-footer {
              &.secondary {
                background-color: $sidebar-background-color; } } }
          .panel-body {
            padding-top: 0;
            padding-bottom: 0;
            min-height: unset;
            #additional-message.optional {
              border-radius: 0;
              border: none;
              background-color: #F8F8F8;
              box-shadow: none;
              padding: 13px 19px;
              font-size: 14px;
              line-height: 16px;
              margin-top: 18px; }
            .vibe-question {
              font-size: 18px;
              padding-bottom: 0;
              margin-bottom: 0; }
            @include mobile-only {
              .vibe-question {
                line-height: rhythm(1);
                font-size: 18px; }
              .vibe-user-avatar {
                width: 112px;
                height: 112px; } }
            .vibe-sentiments-list {
              margin-bottom: 35px;
              margin-top: 36px;
              @include mobile-only {
                margin-top: 50px;
                margin-bottom: 90px; }
              .vibe-sentiment {
                font-size: 16px;
                width: 20%;
                float: left;
                .vibe-sentiment-images {
                  width: 34px;
                  height: 34px;
                  &:hover {
                    transform: scale(1.52); }
                  cursor: pointer; }
                @include mobile-only {
                  font-size: 13px; }
                .vibe-sentiment-round {
                  min-height: 25px;
                  .round {
                    background-color: #DADADA;
                    width: 13px;
                    height: 13px;
                    border-radius: 100%;
                    border: 1px solid white;
                    margin-left: auto;
                    margin-right: auto;
                    will-change: transform;
                    transform: scale(1);
                    transition-duration: 0.03s; } }
                .vibe-sentiment-name {
                  padding-top: 10px;
                  text-transform: uppercase;
                  color: #8A8A8A;
                  font-weight: bold;
                  min-height: 45px;
                  transition-duration: 0.03s;
                  margin-bottom: 5px; }
                &.selected, &.selected:hover {
                  .vibe-sentiment-round .round {
                    background-color: #11A9FF;
                    transform: scale(1.7);
                    @include desktop-only {
                      animation-duration: 0.5s;
                      animation-name: pulse; } }
                  .vibe-sentiment-name {
                    color: #11A9FF;
                    transition-duration: 0.03s; } }
                &:hover {
                  .vibe-sentiment-round .round {
                    background-color: $mid-grey;
                    transform: scale(1.7);
                    transition-duration: 0.03s; }
                  .vibe-sentiment-name {
                    color: $mid-grey;
                    transition-duration: 0.03s; } } }
              .vibe-sentiments-line {
                background-color: #E8E8E8;
                position: relative;
                width: 81%;
                height: 2px;
                margin-left: 10%;
                top: 58px; } }
            .vibe-factors {
              padding: 30px 0 0 0;
              .col-sm-6 {
                padding-left: 12px !important;
                padding-right: 12px !important; }
              .vibe-factor {
                padding: 15px;
                font-size: 13px;
                text-align: center;
                line-height: 17px;
                height: 63px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: normal;
                border: 1px solid #EDEDED;
                border-radius: 6px;
                color: #323232;
                transition: background-color linear .2s;
                margin: 5px 0;
                @media(min-width: $screen-sm) and (max-width:  852px) {
                  height: 88px; }
                &.selected {
                  color: white;
                  line-height: 16px;
                  font-weight: 500; }
                &.disabled {
                  font-style: initial;
                  border-color: $default-border-color;
                  color: $pale-grey;
                  cursor: not-allowed; } } }
            .accordion-factors {
              .accordion-factor {
                margin-bottom: 5px;
                .accordion-factor-title {
                  background-color: $blue;
                  clear: both;
                  cursor: pointer;
                  color: white;
                  @include mobile-only {
                    font-size: 20px; }
                  padding: 14px;
                  font-weight: bold; }
                .vibe-factors {
                  margin-top: 20px;
                  margin-bottom: 20px;
                  display: none;
                  &.open {
                    animation-name: fadeInDown;
                    animation-duration: 0.5s;
                    display: inline-block;
                    @include mobile-only {
                      display: block; } } } } } }
          .vibe-submit {
            padding-top: 30px;
            padding-bottom: 23px;
            #next-step-btn {
              background: #11A9FF;
              border: none;
              border-radius: 4px;
              font-weight: bold;
              font-size: 14px;
              line-height: 16px;
              padding: 9px 28px; }
            .large-button {
              padding-left: 60px;
              padding-right: 60px; } }
          .vibe-anon {
            span.seen-by {
              margin-top: 5px;
              font-size: 12px;
              line-height: 14px;
              color: #ADADAD; }
            span.anonymous-vibe {
              font-size: 12px;
              line-height: 13px;
              color: #6A6A6A;
              cursor: pointer; }
            position: initial;
            cursor: pointer;
            width: 100%;
            font-weight: normal;
            font-size: 11px;
            line-height: 13px;
            color: #6A6A6A;
            @include mobile-only {
              display: none; }
            .icon {
              font-size: 150%;
              vertical-align: middle; } } }
        @include mobile-only {
          margin-top: 0;
          .react-vibe-flow > div, .vibe-step, .panel, .vibe_answer, .frame-wrapper, .page-wrapper {
            height: 100%; }
          .page-wrapper {
            padding: 0; }
          .fullscreen-close {
            font-size: 16px; }
          .nav-page-title-xs {
            margin-bottom: 0; }
          .select_sentiment .panel-body, .good_final_screen .panel-body, .better_final_screen .panel-body {
            width: 100%;
            top: 60%; } } } }
    &.blank-post {
      .blank-post-avatar {
        width: 36px;
        height: 36px;
        background: #EAEAEA;
        border-radius: 100%; }
      .author-info {
        user-select: none;
        .author-name {
          line-height: 14px;
          margin-top: -2px;
          background: #EAEAEA;
          color: rgba(0, 0, 0, 0); }
        .author-role-position {
          background: #F8F8F8;
          line-height: 8px;
          font-size: 12px;
          margin-top: 9px;
          color: rgba(0, 0, 0, 0); } }
      .post-tile-body {
        margin-top: 30px;
        user-select: none;
        margin-bottom: 30px;
        word-wrap: break-word;
        word-break: break-all;
        span {
          background: #EAEAEA;
          color: rgba(0, 0, 0, 0);
          line-height: 32px;
          font-size: 14px; } }
      .post-tile-comments {
        padding: 0;
        .post-tile-comment-input {
          align-items: center; }
        .blank-post-likes-placeholder {
          width: 30px;
          height: 30px;
          border-radius: 100%;
          background: #EAEAEA; }
        .blank-post-comment-placeholder {
          height: 40px;
          background: white;
          flex-grow: 1;
          margin-left: 22px;
          border-radius: 4px; } }
      &.animated {
        @keyframes loadingAnimation {
          0% {
            background-position-x: 200%; }
          100% {
            background-position-x: 0%; } }
        .author-name {
          background: linear-gradient(to right, #EAEAEA 50%, #C4C4C4 75%, #EAEAEA 100%);
          background-size: 200% 100%;
          animation: loadingAnimation infinite 1s; }
        .author-role-position {
          background: linear-gradient(to right, #F8F8F8 50%, #C4C4C4 75%, #F8F8F8 100%);
          background-size: 200% 100%;
          animation: loadingAnimation infinite 1s; }
        .post-tile-body span {
          background: linear-gradient(to right, #EAEAEA 50%, #C4C4C4 75%, #EAEAEA 100%);
          background-size: 200% 100%;
          animation: loadingAnimation infinite 1s; } } }

    &.app-alert-tile {
      padding: 30px 28px 36px;
      .post-tile-header {
        margin: 0; } } } }
